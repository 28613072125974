import * as ScrollMagic from "scrollmagic";
import { TweenMax, Linear } from "gsap";
import "debug.addIndicators";

export default class ScrollVideoSequence {
  constructor(element, controller) {
    this.controller = controller;
    this.config = JSON.parse(
      element.querySelector(`[data-scroll-config='${element.id}']`).innerHTML
    );

    this.video = element.querySelector(".scroll-video-sequence__video");

    const obj = { current: 0 };
    this.canplay = false;

    // create tween
    const tween = TweenMax.to(obj, 0.5, {
      current: this.config.videoDuration * this.config.frames,
      roundProps: "current",
      immediateRender: true,
      ease: Linear.easeNone,
    });

    this.duration = (this.config.duration * window.innerHeight) / 100;
    this.offset = element.getBoundingClientRect().top;

    this.scene = new ScrollMagic.Scene({
      duration: this.duration,
      offset: this.offset,
    })
      .setTween(tween)
      .setPin(element);

    if (this.config.debug) {
      this.scene.addIndicators();
    }

    this.scene.addTo(this.controller);

    const scrollPlay = () => {
      this.video.currentTime = obj.current / this.config.frames;
      window.requestAnimationFrame(scrollPlay);
    };
    window.requestAnimationFrame(scrollPlay);
  }
}
