export const buildThresholdList = (steps = 20) => {
  const thresholds = [];

  for (let i = 1.0; i <= steps; i++) {
    const ratio = i / steps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};
