class NextProject {
  constructor(element) {
    this.element = element;
    this.href = element.href;

    this.timeout = null;

    const observer = new window.IntersectionObserver(
      ([entry]) => this.handleIntersect(entry.intersectionRatio >= 0.75),
      {
        root: null,
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    observer.observe(element);
  }

  handleIntersect(intersecting) {
    if (intersecting) {
      
      console.log("Is intersecting");

      this.element.classList.add("animate");

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        window.location.href = this.href;
      }, 2500);
    }

    if (!intersecting) {
      this.element.classList.remove("animate");
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    }
  }
}

export const init = () => {
  const items = document.querySelectorAll(".next-project");

  items.forEach((item) => {
    new NextProject(item);
  });
};

export default NextProject;
