import * as ScrollMagic from "scrollmagic";
import "debug.addIndicators";

import ScrollImage from "./image";
import ScrollImageSequence from "./image-sequence";
import ScrollVideoSequence from "./video-sequence";

export default class ScrollBlocks {
  constructor(blocks) {
    this.blocks = blocks;

    this.controller = new ScrollMagic.Controller();

    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    this.initScrollBlocks();
  }

  initScrollBlocks() {
    this.blocks.forEach((block) => {
      const type = block.getAttribute("data-scroll-block");
      if (type === "image") {
        new ScrollImage(block, this.controller);
      } else if (type === "image-sequence") {
        new ScrollImageSequence(block, this.controller);
      } else if (type === "video-sequence") {
        new ScrollVideoSequence(block, this.controller);
      }
    });
  }
}

export const init = () => {
  const blocks = document.querySelectorAll("[data-scroll-block]");

  if (blocks.length > 0) {
    new ScrollBlocks(blocks);
  }
};
