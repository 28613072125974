import throttle from "./helper/throttle";

class Header {
  constructor(element) {
    this.element = element;
    this.wrapper = element.querySelector(".header__wrapper");
    this.burger = element.querySelector(".header__burger");
    this.type = element.dataset.type;
    this.hasIntro = element.dataset.hasIntro === "true";
    this.main = document.querySelector("main");

    window.addEventListener(
      "scroll",
      throttle(this.checkScrollPosition.bind(this), 50)
    );

    window.addEventListener(
      "resize",
      throttle(this.checkScrollPosition.bind(this), 50)
    );

    this.scrollPosition = window.scrollY;
    this.direction = "down";

    this.checkScrollPosition();

    this.burger.addEventListener("click", this.handleBurgerClick.bind(this));
  }

  handleBurgerClick() {
    if (this.element.classList.contains("navigation-open")) {
      this.element.classList.remove("navigation-open");
    } else {
      this.element.classList.add("navigation-open");
    }
  }

  checkScrollPosition() {
    const height = window.innerHeight * 0.2;

    this.direction = window.scrollY > this.scrollPosition ? "down" : "up";
    this.scrollPosition = window.scrollY;

    if (this.direction === "down" && this.scrollPosition > height) {
      this.element.classList.remove("is-up");
      this.element.classList.add("is-down");
    } else {
      this.element.classList.remove("is-down");
      this.element.classList.add("is-up");
    }

    if (this.hasIntro) {
      if (window.scrollY + 100 >= window.innerHeight) {
        this.element.classList.add("intro-hidden");
      } else {
        this.element.classList.remove("intro-hidden");
      }
    }

    const { top } = this.main.getBoundingClientRect();

    if (this.type !== "default") {
      if (top < 0) {
        this.element.classList.add("is-scrolled");
      } else {
        this.element.classList.remove("is-scrolled");
      }
    }
  }
}

export const init = () => {
  const headers = document.querySelectorAll(".header");

  headers.forEach((header) => {
    new Header(header);
  });
};

export default Header;
