class Footer {
  constructor(element) {
    this.backToTop = element.querySelector(".footer__back-to-top");

    if (document.body.scrollHeight > window.innerHeight * 2) {
      this.backToTop.classList.add("is-visible");
    }

    this.backToTop.addEventListener("click", () => {
      const headerSpacer = document.querySelector(".header__spacer");

      const top = headerSpacer
        ? headerSpacer.getBoundingClientRect().top + window.scrollY
        : 0;

      window.scrollTo({
        top,
        left: 0,
        behavior: "smooth",
      });
    });

    const observer = new window.IntersectionObserver(
      ([entry]) => {
        this.handleIntersect(entry.intersectionRatio >= 0.5);
      },
      {
        root: null,
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    observer.observe(element);
  }

  handleIntersect(intersecting) {
    if (intersecting) document.body.classList.add("footer-visible");
    else document.body.classList.remove("footer-visible");
  }
}

export const init = () => {
  const footers = document.querySelectorAll(".footer");

  footers.forEach((footer) => {
    new Footer(footer);
  });
};

export default Footer;
