class ContactForm {
  constructor(element) {
    this.element = element;
    this.notice = element.querySelector(".contact-form__notice");
    element.addEventListener("submit", (e) => this.submitForm(e));

    this.csrf = null;

    this.getCsrfToken();
  }

  getCsrfToken() {
    fetch("/forms/csrf")
      .then((response) => response.json())
      .then((json) => {
        this.csrf = json.token;
      })
      .catch((err) => console.log("ERR", err));
  }

  submitForm(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    let data = {};

    for (var [key, value] of formData.entries()) {
      data = {
        ...data,
        [key]: value,
      };
    }

    fetch("/forms/contact", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ csrf_token: this.csrf, ...data }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.notice.classList.add(json.type);
        if (json.message) this.notice.innerHTML = json.message;
        if (json.type === "success") this.element.reset();
      })
      .catch((err) => console.log("ERR", err));
  }
}

export const init = () => {
  const contactForms = document.querySelectorAll(".contact-form");

  contactForms.forEach((form) => {
    new ContactForm(form);
  });
};

export default ContactForm;
