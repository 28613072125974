import debounce from "lodash.debounce";
import { buildThresholdList } from "./helper/buildThresholdlist";

class IntroVideo {
  constructor(element) {
    this.element = element;
    this.video = element.querySelector("video");
    this.muteButton = element.querySelector(".intro-video__mute");
    this.scrollButton = document.querySelector(".intro-video__scroll");
    this.video.muted = true;

    this.updateVideoSize();
    this.initEventListeners();
  }

  toggleMute(muted) {
    if (!this.muteButton) return;

    if (muted) {
      this.muteButton.classList.remove("muted");
      this.video.currentTime = 0;
      this.video.muted = false;
    } else {
      this.muteButton.classList.add("muted");
      this.video.muted = true;
    }
  }

  updateVideoSize() {
    const currentVideoSrc = this.video.src;
    let newSrc = null;

    if (window.innerWidth < 768 && this.video.getAttribute("data-mobile-src")) {
      newSrc = this.video.getAttribute("data-mobile-src");
    } else {
      newSrc = this.video.getAttribute("data-desktop-src");
    }

    if (currentVideoSrc !== newSrc) this.video.src = newSrc;
  }

  initEventListeners() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.toggleMute(true);
            this.video.play();
          } else {
            this.video.pause();
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: buildThresholdList(),
      }
    );

    observer.observe(this.element);

    this.scrollButton.classList.remove("hidden");
    this.scrollButton.addEventListener("click", () => {
      const container = document.getElementById("content");
      const { y } = container.getBoundingClientRect();
      window.scrollTo({ top: y + window.scrollY, behavior: "smooth" });
    });

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.scrollButton.classList.add("hidden");
      } else {
        this.scrollButton.classList.remove("hidden");
      }
    });

    window.addEventListener(
      "resize",
      debounce(() => this.updateVideoSize(), 400)
    );

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.scrollButton.classList.add("hidden");
      } else {
        this.scrollButton.classList.remove("hidden");
      }
    });

    if (this.muteButton) {
      this.muteButton.addEventListener("click", () => {
        const isMuted = this.muteButton.classList.contains("muted");
        this.toggleMute(isMuted);
      });
    }
  }
}

export const init = () => {
  const introVideos = document.querySelectorAll(".intro-video");

  introVideos.forEach((footer) => {
    new IntroVideo(footer);
  });
};

export default IntroVideo;
