import * as ScrollMagic from "scrollmagic";
import { TweenMax, Power2 } from "gsap";
import "debug.addIndicators";

export default class ScrollImage {
  constructor(element, controller) {
    this.controller = controller;

    this.config = JSON.parse(
      element.querySelector(`[data-scroll-config='${element.id}']`).innerHTML
    );

    this.image = element.querySelector(".scroll-image__container");

    this.duration = (this.config.duration * window.innerHeight) / 100;
    this.offset = element.getBoundingClientRect().top;

    this.scene = new ScrollMagic.Scene({
      duration: this.duration,
      offset: this.offset,
    }).setPin(element);

    this.initScene();
  }

  initScene() {
    if (this.config.debug) {
      this.scene.addIndicators();
    }

    this.config.keyframes.forEach((i, index) => {
      if (index === 0 && i.start === 0 && i.end === 0) {
        this.buildInitialKeyframe(i);
      } else {
        this.buildKeyframe(i);
      }
    });

    this.scene.addTo(this.controller);
  }

  buildInitialKeyframe({ x, y, opacity, scale }) {
    const config = {
      y: `${y}%`,
      x: `${x}%`,
      opacity,
      scale,
      ease: Power2.easeInOut,
    };

    const tween = new TweenMax.to(this.image, 1, config);

    const scene = new ScrollMagic.Scene({
      duration: this.offset,
      offset: 0,
    }).setTween(tween);

    if (this.config.debug) {
      scene.addIndicators({
        name: `| initial keyframe`,
      });
    }

    scene.addTo(this.controller);
  }

  buildKeyframe({ start, end, x, y, opacity, scale }) {
    const offsetStart = (this.duration / 100) * start;
    const offsetEnd = (this.duration / 100) * end;
    const duration = offsetEnd - offsetStart;

    const config = {
      y: `${y}%`,
      x: `${x}%`,
      opacity,
      scale,
      ease: Power2.easeInOut,
    };
    const tween = new TweenMax.to(this.image, 1, config);

    const scene = new ScrollMagic.Scene({
      duration,
      offset: offsetStart + this.offset,
    }).setTween(tween);

    if (this.config.debug) {
      scene.addIndicators({
        name: `${start} – ${end}% | x ${x}% | y ${y}% | scale ${scale} | opacity ${opacity}`,
      });
    }

    scene.addTo(this.controller);
  }
}

export const init = () => {
  const elements = document.querySelectorAll("[data-scroll-block='image']");

  elements.forEach((element) => {
    new ScrollImage(element);
  });
};
