import "lazysizes";

import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

import { init as initScrollBlocks } from "./scroll-blocks";
import { init as initHeader } from "./header";
import { init as initFooter } from "./footer";
import { init as initIntroVideo } from "./intro-video";
import { init as initVideo } from "./video";
import { init as initNextProject } from "./next-project";
import { init as initContactForm } from "./contact-form";
import initCookieLayer from "./cookie-layer";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

(() => {
  initCookieLayer();
  initHeader();
  initFooter();
  initIntroVideo();
  initVideo();
  initContactForm();
  initNextProject();
  initScrollBlocks();
})();
