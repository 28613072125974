import * as Klaro from "klaro/dist/klaro-no-css";

export default function init() {
  const config = {
    version: 1,

    styling: {
      theme: ["dark"],
    },

    default: true,
    acceptAll: true,
    hideDeclineAll: true,
    hideLearnMore: true,
    translations: {
      en: {
        acceptAll: "Accept all",
        ok: "Accept",
        decline: "Accept necessary",
        privacyPolicyUrl: "/privacy-policy",
        privacyPolicy: {
          text: "For more information, please visit our {privacyPolicy}.",
        },
        consentNotice: {
          learnMore: "Show details",
          description:
            "By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.",
        },
      },
      de: {
        acceptAll: "Alle Cookies akzeptieren",
        acceptSelected: "Meine Auswahl erlauben",
        ok: "Akzeptieren",
        decline: "Nur notwendige Cookies",
        privacyPolicyUrl: "/datenschutz",
        privacyPolicy: {
          text: "Weiter Informationen dazu finden Sie in unserer {privacyPolicy}.",
        },
        consentModal: {
          description:
            "By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.",
        },
        consentNotice: {
          description:
            "Auf dieser Website werden Cookies eingesetzt. Dies umfasst Cookies, die für den Betrieb unserer Website notwendig sind, sowie weitere, die wir u.a. für Werbezwecke, Zwecke in Verbindung mit Social Media sowie für analytische Zwecke einsetzen. Weiter Informationen dazu finden Sie in unserer {privacyPolicy}.",
          learnMore: "Details anzeigen",
        },
        purposes: {
          analytics: {
            title: "Statistik-Cookies",
          },
          functional: {
            title: "Notwendige Cookies",
          },
        },
      },
    },

    services: [
      {
        name: "googleAnalytics",
        onlyOnce: true,
        purposes: ["analytics"],
      },
      {
        name: "vimeo",
        contextualConsentOnly: true,
        purposes: ["marketing"],
      },
    ],
  };

  // we assign the Klaro module to the window, so that we can access it in JS
  window.klaro = Klaro;
  window.klaroConfig = config;
  // we set up Klaro with the config
  Klaro.setup(config);
}
