import debounce from "lodash.debounce";
import { buildThresholdList } from "./helper/buildThresholdlist";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

class Video {
  constructor(element) {
    this.element = element;

    this.src = null;
    this.margin = element.dataset.margin || "100";

    this.updateVideoSize();
    this.initEventListeners();
  }

  updateVideoSize() {
    let newSrc = null;

    if (
      window.innerWidth < 768 &&
      this.element.getAttribute("data-mobile-src")
    ) {
      newSrc = this.element.getAttribute("data-mobile-src");
    } else {
      newSrc = this.element.getAttribute("data-desktop-src");
    }

    this.src = newSrc;

    if (this.element.src && this.element.src !== newSrc) {
      this.element.src = newSrc;
    }
  }

  initEventListeners() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.element.src = this.src;
            this.element.controls = false;
            this.element.playsinline = true;
            this.element.muted = true;
            this.element.setAttribute("muted", "");
            this.element.autoplay = true;
            this.element.loop = true;
            this.element.play();
            observer.unobserve(entry.target);
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: `${this.margin}px 0px 0px 0px`,
        threshold: buildThresholdList(100),
      }
    );

    observer.observe(this.element);

    window.addEventListener(
      "resize",
      debounce(() => this.updateVideoSize(), 400)
    );

    if (isSafari()) {
      this.element.addEventListener("canplay", () => {
        this.element.controls = false;
        this.element.playsinline = true;
        this.element.muted = true;
        this.element.setAttribute("muted", "");
        this.element.autoplay = true;
        this.element.loop = true;

        setTimeout(() => {
          const promise = this.element.play();
        }, 0);
      });
    }
  }
}

export const init = () => {
  const elements = document.querySelectorAll("video[data-desktop-src]");

  elements.forEach((footer) => {
    new Video(footer);
  });
};

export default Video;
